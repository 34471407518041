import {useAuthStore} from '~/store/auth'

export default defineNuxtRouteMiddleware((to, from) => {
    const authStore = useAuthStore()

    const securedHomePage = "/service"

    const authPage = "auth"

    //Don´t do anything on this pages
    const exceptions = ['auth-activate', 'auth-social', 'index']


    for (let i = 0; i < exceptions.length; i++) {
        if (to.name != null && to.name.startsWith(exceptions[i])) {
            return
        }
    }

    // If the user is not yet authenticated
    if ((authStore.isAuthenticated ?? null) === null && process.client) {
        //////////////////////////////////////////////////////////////////////////////////////
        // FAST BOOT:
        // Will Speed Up Page Load
        // If user is authenticated via localstorage and has any ability
        // Make site already visible, will be maybe changed in the following request
        // Set abilities from localStorage, will be maybe changed in the following request
        //////////////////////////////////////////////////////////////////////////////////////
        if (localStorage.getItem('isAuthenticated') === 'true'
            && localStorage.getItem('abilities')
            && localStorage.getItem('abilities') !== 'undefined') {

            authStore.abilities = JSON.parse(localStorage.getItem('abilities'))
            authStore.userData = JSON.parse(localStorage.getItem('userData'))

            if (to.name != null && to.name?.startsWith('auth')) {
                return navigateTo(securedHomePage)
            }
        }
            //////////////////////////////////////////////////////////////////////////////////////
            // If user is not authenticated via localstorage
            // Redirect User to Auth Page.
        //////////////////////////////////////////////////////////////////////////////////////
        else {
            if (to.name === null || !to.name?.startsWith(authPage)) {
                return navigateTo("/" + authPage)
            }
        }

        //Now check via ajax if user is really authenticated
        authStore.getAuthData().then(
            //And then redirect accordingly
            () => redirectRules({authStore, to, navigateTo, authPage, securedHomePage})
        )
    } else {
        redirectRules({authStore, to, navigateTo, authPage, securedHomePage});
    }
})

function redirectRules({authStore, to, navigateTo, authPage, securedHomePage}) {
    if (!authStore.isAuthenticated && !to.name?.startsWith('auth')) {
        return navigateTo("/" + authPage)
    }

    if (authStore.isAuthenticated && to.name === null) {
        return navigateTo(securedHomePage)
    }

    if (authStore.isAuthenticated && to.name?.startsWith('auth')) {
        return navigateTo(securedHomePage)
    }
}