import {defineStore} from 'pinia'

export const useLayoutStore = defineStore('layout', {
    state: () => ({
        isSidebarOpen: false,
        isSubheaderOpen: false,
        currentModule: null,
    }),

    actions: {
        setSidebarOpen(isSidebarOpen) {
            this.isSidebarOpen = isSidebarOpen
        },
        setCurrentModule(currentModule) {
            this.currentModule = currentModule
        },
    },
})
