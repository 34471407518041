import { default as indexjRdSZVYY6kMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/auth/index.vue?macro=true";
import { default as indexCA0b8VFQDvMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/auth/social/[organisation]/[provider]/index.vue?macro=true";
import { default as indexUq0EUsq1ayMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/index/index.vue?macro=true";
import { default as orderLsWfLaa4CKMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/index/order.vue?macro=true";
import { default as orderSuccessnGqfnA5VzEMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/index/orderSuccess.vue?macro=true";
import { default as indexgT1L1GcVGGMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/index.vue?macro=true";
import { default as attributeBaseDataeK5TeOMhoSMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/configurator/attributes/[id]/attributeBaseData.vue?macro=true";
import { default as _91id_93PSBftiARBdMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/configurator/attributes/[id].vue?macro=true";
import { default as componentBaseDatak1Cj36U9FPMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/configurator/components/[id]/componentBaseData.vue?macro=true";
import { default as _91id_93HVTYgIyb58Meta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/configurator/components/[id].vue?macro=true";
import { default as index2sbhTK3Dx7Meta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/configurator/components/index.vue?macro=true";
import { default as variantBaseDataAMLDGGsifxMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/configurator/variants/[id]/variantBaseData.vue?macro=true";
import { default as _91id_93coqEsFDEFwMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/configurator/variants/[id].vue?macro=true";
import { default as indexm7v27t2pJJMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/configurator/variants/index.vue?macro=true";
import { default as configurator6O6jfig0t0Meta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/configurator.vue?macro=true";
import { default as indexrlf1TD66K5Meta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/index.vue?macro=true";
import { default as orderBaseDataJU2cyMqGniMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/orders/[id]/orderBaseData.vue?macro=true";
import { default as _91id_93oUnird0DUkMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/orders/[id].vue?macro=true";
import { default as indexfpX65rTQ4xMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/orders/index.vue?macro=true";
import { default as languagewK6fVtLyjsMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/settings/language.vue?macro=true";
import { default as _91id_935Z6Efa1C9zMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/settings/loginProviders/[id].vue?macro=true";
import { default as indexJ8ugiyYZLAMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/settings/loginProviders/index.vue?macro=true";
import { default as rolesqD9Q7UTmSTMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/settings/roles.vue?macro=true";
import { default as settingsTZf7NKWliWMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/settings.vue?macro=true";
import { default as userActivitiesPd1GcyZI7SMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDataXYEm58RtPWMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/users/[id]/userBaseData.vue?macro=true";
import { default as userCommunicationsMSPjdsj1HOMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/users/[id]/userCommunications.vue?macro=true";
import { default as _91id_938jh6rAH8seMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/users/[id].vue?macro=true";
import { default as indexPb1wWKh6N2Meta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/users/index.vue?macro=true";
import { default as users4wkBsGF32SMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/users.vue?macro=true";
import { default as service3xIOKRnbbOMeta } from "/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service.vue?macro=true";
export default [
  {
    name: indexjRdSZVYY6kMeta?.name ?? "auth",
    path: indexjRdSZVYY6kMeta?.path ?? "/auth",
    meta: indexjRdSZVYY6kMeta || {},
    alias: indexjRdSZVYY6kMeta?.alias || [],
    redirect: indexjRdSZVYY6kMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexCA0b8VFQDvMeta?.name ?? "auth-social-organisation-provider",
    path: indexCA0b8VFQDvMeta?.path ?? "/auth/social/:organisation()/:provider()",
    meta: indexCA0b8VFQDvMeta || {},
    alias: indexCA0b8VFQDvMeta?.alias || [],
    redirect: indexCA0b8VFQDvMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/auth/social/[organisation]/[provider]/index.vue").then(m => m.default || m)
  },
  {
    path: indexgT1L1GcVGGMeta?.path ?? "/",
    children: [
  {
    name: indexUq0EUsq1ayMeta?.name ?? "index",
    path: indexUq0EUsq1ayMeta?.path ?? "",
    meta: indexUq0EUsq1ayMeta || {},
    alias: indexUq0EUsq1ayMeta?.alias || [],
    redirect: indexUq0EUsq1ayMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: orderLsWfLaa4CKMeta?.name ?? "index-order",
    path: orderLsWfLaa4CKMeta?.path ?? "order",
    meta: orderLsWfLaa4CKMeta || {},
    alias: orderLsWfLaa4CKMeta?.alias || [],
    redirect: orderLsWfLaa4CKMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/index/order.vue").then(m => m.default || m)
  },
  {
    name: orderSuccessnGqfnA5VzEMeta?.name ?? "index-orderSuccess",
    path: orderSuccessnGqfnA5VzEMeta?.path ?? "orderSuccess",
    meta: orderSuccessnGqfnA5VzEMeta || {},
    alias: orderSuccessnGqfnA5VzEMeta?.alias || [],
    redirect: orderSuccessnGqfnA5VzEMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/index/orderSuccess.vue").then(m => m.default || m)
  }
],
    name: indexgT1L1GcVGGMeta?.name ?? undefined,
    meta: indexgT1L1GcVGGMeta || {},
    alias: indexgT1L1GcVGGMeta?.alias || [],
    redirect: indexgT1L1GcVGGMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/index.vue").then(m => m.default || m)
  },
  {
    path: service3xIOKRnbbOMeta?.path ?? "/service",
    children: [
  {
    name: configurator6O6jfig0t0Meta?.name ?? "service-configurator",
    path: configurator6O6jfig0t0Meta?.path ?? "configurator",
    children: [
  {
    name: _91id_93PSBftiARBdMeta?.name ?? "service-configurator-attributes-id",
    path: _91id_93PSBftiARBdMeta?.path ?? "attributes/:id()",
    children: [
  {
    name: attributeBaseDataeK5TeOMhoSMeta?.name ?? "service-configurator-attributes-id-attributeBaseData",
    path: attributeBaseDataeK5TeOMhoSMeta?.path ?? "attributeBaseData",
    meta: attributeBaseDataeK5TeOMhoSMeta || {},
    alias: attributeBaseDataeK5TeOMhoSMeta?.alias || [],
    redirect: attributeBaseDataeK5TeOMhoSMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/configurator/attributes/[id]/attributeBaseData.vue").then(m => m.default || m)
  }
],
    meta: _91id_93PSBftiARBdMeta || {},
    alias: _91id_93PSBftiARBdMeta?.alias || [],
    redirect: _91id_93PSBftiARBdMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/configurator/attributes/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93HVTYgIyb58Meta?.name ?? "service-configurator-components-id",
    path: _91id_93HVTYgIyb58Meta?.path ?? "components/:id()",
    children: [
  {
    name: componentBaseDatak1Cj36U9FPMeta?.name ?? "service-configurator-components-id-componentBaseData",
    path: componentBaseDatak1Cj36U9FPMeta?.path ?? "componentBaseData",
    meta: componentBaseDatak1Cj36U9FPMeta || {},
    alias: componentBaseDatak1Cj36U9FPMeta?.alias || [],
    redirect: componentBaseDatak1Cj36U9FPMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/configurator/components/[id]/componentBaseData.vue").then(m => m.default || m)
  }
],
    meta: _91id_93HVTYgIyb58Meta || {},
    alias: _91id_93HVTYgIyb58Meta?.alias || [],
    redirect: _91id_93HVTYgIyb58Meta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/configurator/components/[id].vue").then(m => m.default || m)
  },
  {
    name: index2sbhTK3Dx7Meta?.name ?? "service-configurator-components",
    path: index2sbhTK3Dx7Meta?.path ?? "components",
    meta: index2sbhTK3Dx7Meta || {},
    alias: index2sbhTK3Dx7Meta?.alias || [],
    redirect: index2sbhTK3Dx7Meta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/configurator/components/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93coqEsFDEFwMeta?.name ?? "service-configurator-variants-id",
    path: _91id_93coqEsFDEFwMeta?.path ?? "variants/:id()",
    children: [
  {
    name: variantBaseDataAMLDGGsifxMeta?.name ?? "service-configurator-variants-id-variantBaseData",
    path: variantBaseDataAMLDGGsifxMeta?.path ?? "variantBaseData",
    meta: variantBaseDataAMLDGGsifxMeta || {},
    alias: variantBaseDataAMLDGGsifxMeta?.alias || [],
    redirect: variantBaseDataAMLDGGsifxMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/configurator/variants/[id]/variantBaseData.vue").then(m => m.default || m)
  }
],
    meta: _91id_93coqEsFDEFwMeta || {},
    alias: _91id_93coqEsFDEFwMeta?.alias || [],
    redirect: _91id_93coqEsFDEFwMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/configurator/variants/[id].vue").then(m => m.default || m)
  },
  {
    name: indexm7v27t2pJJMeta?.name ?? "service-configurator-variants",
    path: indexm7v27t2pJJMeta?.path ?? "variants",
    meta: indexm7v27t2pJJMeta || {},
    alias: indexm7v27t2pJJMeta?.alias || [],
    redirect: indexm7v27t2pJJMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/configurator/variants/index.vue").then(m => m.default || m)
  }
],
    meta: configurator6O6jfig0t0Meta || {},
    alias: configurator6O6jfig0t0Meta?.alias || [],
    redirect: configurator6O6jfig0t0Meta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/configurator.vue").then(m => m.default || m)
  },
  {
    name: indexrlf1TD66K5Meta?.name ?? "service",
    path: indexrlf1TD66K5Meta?.path ?? "",
    meta: indexrlf1TD66K5Meta || {},
    alias: indexrlf1TD66K5Meta?.alias || [],
    redirect: indexrlf1TD66K5Meta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93oUnird0DUkMeta?.name ?? "service-orders-id",
    path: _91id_93oUnird0DUkMeta?.path ?? "orders/:id()",
    children: [
  {
    name: orderBaseDataJU2cyMqGniMeta?.name ?? "service-orders-id-orderBaseData",
    path: orderBaseDataJU2cyMqGniMeta?.path ?? "orderBaseData",
    meta: orderBaseDataJU2cyMqGniMeta || {},
    alias: orderBaseDataJU2cyMqGniMeta?.alias || [],
    redirect: orderBaseDataJU2cyMqGniMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/orders/[id]/orderBaseData.vue").then(m => m.default || m)
  }
],
    meta: _91id_93oUnird0DUkMeta || {},
    alias: _91id_93oUnird0DUkMeta?.alias || [],
    redirect: _91id_93oUnird0DUkMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexfpX65rTQ4xMeta?.name ?? "service-orders",
    path: indexfpX65rTQ4xMeta?.path ?? "orders",
    meta: indexfpX65rTQ4xMeta || {},
    alias: indexfpX65rTQ4xMeta?.alias || [],
    redirect: indexfpX65rTQ4xMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/orders/index.vue").then(m => m.default || m)
  },
  {
    name: settingsTZf7NKWliWMeta?.name ?? "service-settings",
    path: settingsTZf7NKWliWMeta?.path ?? "settings",
    children: [
  {
    name: languagewK6fVtLyjsMeta?.name ?? "service-settings-language",
    path: languagewK6fVtLyjsMeta?.path ?? "language",
    meta: languagewK6fVtLyjsMeta || {},
    alias: languagewK6fVtLyjsMeta?.alias || [],
    redirect: languagewK6fVtLyjsMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/settings/language.vue").then(m => m.default || m)
  },
  {
    name: _91id_935Z6Efa1C9zMeta?.name ?? "service-settings-loginProviders-id",
    path: _91id_935Z6Efa1C9zMeta?.path ?? "loginProviders/:id()",
    meta: _91id_935Z6Efa1C9zMeta || {},
    alias: _91id_935Z6Efa1C9zMeta?.alias || [],
    redirect: _91id_935Z6Efa1C9zMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexJ8ugiyYZLAMeta?.name ?? "service-settings-loginProviders",
    path: indexJ8ugiyYZLAMeta?.path ?? "loginProviders",
    meta: indexJ8ugiyYZLAMeta || {},
    alias: indexJ8ugiyYZLAMeta?.alias || [],
    redirect: indexJ8ugiyYZLAMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/settings/loginProviders/index.vue").then(m => m.default || m)
  },
  {
    name: rolesqD9Q7UTmSTMeta?.name ?? "service-settings-roles",
    path: rolesqD9Q7UTmSTMeta?.path ?? "roles",
    meta: rolesqD9Q7UTmSTMeta || {},
    alias: rolesqD9Q7UTmSTMeta?.alias || [],
    redirect: rolesqD9Q7UTmSTMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/settings/roles.vue").then(m => m.default || m)
  }
],
    meta: settingsTZf7NKWliWMeta || {},
    alias: settingsTZf7NKWliWMeta?.alias || [],
    redirect: settingsTZf7NKWliWMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/settings.vue").then(m => m.default || m)
  },
  {
    path: users4wkBsGF32SMeta?.path ?? "users",
    children: [
  {
    name: _91id_938jh6rAH8seMeta?.name ?? "service-users-id",
    path: _91id_938jh6rAH8seMeta?.path ?? ":id()",
    children: [
  {
    name: userActivitiesPd1GcyZI7SMeta?.name ?? "service-users-id-userActivities",
    path: userActivitiesPd1GcyZI7SMeta?.path ?? "userActivities",
    meta: userActivitiesPd1GcyZI7SMeta || {},
    alias: userActivitiesPd1GcyZI7SMeta?.alias || [],
    redirect: userActivitiesPd1GcyZI7SMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: userBaseDataXYEm58RtPWMeta?.name ?? "service-users-id-userBaseData",
    path: userBaseDataXYEm58RtPWMeta?.path ?? "userBaseData",
    meta: userBaseDataXYEm58RtPWMeta || {},
    alias: userBaseDataXYEm58RtPWMeta?.alias || [],
    redirect: userBaseDataXYEm58RtPWMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/users/[id]/userBaseData.vue").then(m => m.default || m)
  },
  {
    name: userCommunicationsMSPjdsj1HOMeta?.name ?? "service-users-id-userCommunications",
    path: userCommunicationsMSPjdsj1HOMeta?.path ?? "userCommunications",
    meta: userCommunicationsMSPjdsj1HOMeta || {},
    alias: userCommunicationsMSPjdsj1HOMeta?.alias || [],
    redirect: userCommunicationsMSPjdsj1HOMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/users/[id]/userCommunications.vue").then(m => m.default || m)
  }
],
    meta: _91id_938jh6rAH8seMeta || {},
    alias: _91id_938jh6rAH8seMeta?.alias || [],
    redirect: _91id_938jh6rAH8seMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPb1wWKh6N2Meta?.name ?? "service-users",
    path: indexPb1wWKh6N2Meta?.path ?? "",
    meta: indexPb1wWKh6N2Meta || {},
    alias: indexPb1wWKh6N2Meta?.alias || [],
    redirect: indexPb1wWKh6N2Meta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/users/index.vue").then(m => m.default || m)
  }
],
    name: users4wkBsGF32SMeta?.name ?? undefined,
    meta: users4wkBsGF32SMeta || {},
    alias: users4wkBsGF32SMeta?.alias || [],
    redirect: users4wkBsGF32SMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service/users.vue").then(m => m.default || m)
  }
],
    name: service3xIOKRnbbOMeta?.name ?? undefined,
    meta: service3xIOKRnbbOMeta || {},
    alias: service3xIOKRnbbOMeta?.alias || [],
    redirect: service3xIOKRnbbOMeta?.redirect || undefined,
    component: () => import("/home/forge/konfigurator.smartdusche.com/releases/20240902100858/client/pages/service.vue").then(m => m.default || m)
  }
]