import { defineNuxtPlugin } from '#app/nuxt'
import { LazyTableFilterBoolean, LazyTableFilterNewFilterTemplate, LazyTableFilterNumeric, LazyTableFilterSelect, LazyTableFilterString, LazyTableToolAttributesCreate, LazyTableToolComponentsCreate, LazyTableToolGeneralExport, LazyTableToolLoginProvidersCreate, LazyTableToolOrganisationsCreate, LazyTableToolOrganisationsToggle, LazyTableToolUsersCreate, LazyTableToolUsersDelete, LazyTableToolVariantsCreate, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["TableFilterBoolean", LazyTableFilterBoolean],
["TableFilterNewFilterTemplate", LazyTableFilterNewFilterTemplate],
["TableFilterNumeric", LazyTableFilterNumeric],
["TableFilterSelect", LazyTableFilterSelect],
["TableFilterString", LazyTableFilterString],
["TableToolAttributesCreate", LazyTableToolAttributesCreate],
["TableToolComponentsCreate", LazyTableToolComponentsCreate],
["TableToolGeneralExport", LazyTableToolGeneralExport],
["TableToolLoginProvidersCreate", LazyTableToolLoginProvidersCreate],
["TableToolOrganisationsCreate", LazyTableToolOrganisationsCreate],
["TableToolOrganisationsToggle", LazyTableToolOrganisationsToggle],
["TableToolUsersCreate", LazyTableToolUsersCreate],
["TableToolUsersDelete", LazyTableToolUsersDelete],
["TableToolVariantsCreate", LazyTableToolVariantsCreate],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS]
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
