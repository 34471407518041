import {setLocale as setVeeLocale} from "@vee-validate/i18n";

export default defineNuxtRouteMiddleware((to, from) => {
    const lang = localStorage.getItem('lang')
    const app = useNuxtApp()

    const setToGerman = async () => {
        localStorage.setItem('lang', 'de')
        await app.$i18n.setLocale('de')
        setVeeLocale('de')
    }

    if (lang !== 'de' || app.$i18n.locale !== 'de') {
        setToGerman()
    }
})